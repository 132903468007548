export class HowBox {

    constructor(element) {
        this.element = element;
        this.target = document.querySelector(element.dataset.target);
        this.run();
    }

    run() {

        const that = this;
        this.element.addEventListener('change', function () {
            const value = that.element.value;
            if (value == 'newspaper') {
                that.target.classList.remove('is-hidden');
                that.target.querySelector('input').focus();
            } else {
                that.target.classList.add('is-hidden');
            }
        });

    }

}
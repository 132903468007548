import { library, dom } from '@fortawesome/fontawesome-svg-core'

import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown'
import { faCreditCard } from '@fortawesome/pro-light-svg-icons/faCreditCard'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle'
import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa'
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons/faCcMastercard';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons/faSpinner';

library.add(faArrowDown, faCreditCard, faCheck, faQuestionCircle, faCcVisa, faCcMastercard, faSpinner);

dom.watch();

import Inputmask from 'inputmask';

export class ProvinceState {

    constructor(element) {
        this.element = element;
        this.targetUS = document.querySelector(element.dataset.targetUs);
        this.targetCA = document.querySelector(element.dataset.targetCa);
        this.targetWorld = document.querySelector(element.dataset.targetWorld);
        this.targetPostalCode = document.querySelector(element.dataset.targetPostalcode);
        this.run();
    }

    setupCAMask() {
        this.removeMask();
        const that = this;
        this.im = Inputmask('A9A 9A9', {
            clearIncomplete: true
        }).mask(this.targetPostalCode);
    }

    setupUSMask() {
        this.removeMask();
        const that = this;
        this.im = Inputmask('99999', {
            'onincomplete': function () {
                that.targetPostalCode.value = null;
            }
        }).mask(this.targetPostalCode);
    }

    removeMask() {
        if (typeof this.im !== 'undefined') {
            this.im.remove();
        }
    }

    run() {

        const that = this;

        this.setupCAMask();

        this.element.addEventListener('change', function () {

            const country = that.element.value;

            if (country == 'CA') {
                that.setupCAMask();
                that.targetUS.classList.add('is-hidden');
                that.targetWorld.classList.add('is-hidden');
                that.targetCA.classList.remove('is-hidden');
            } else if (country == 'US') {
                that.setupUSMask();
                that.targetUS.classList.remove('is-hidden');
                that.targetWorld.classList.add('is-hidden');
                that.targetCA.classList.add('is-hidden');
            } else {
                that.removeMask();
                that.targetUS.classList.add('is-hidden');
                that.targetWorld.classList.remove('is-hidden');
                that.targetCA.classList.add('is-hidden');
            }
        });
    }

}
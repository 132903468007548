import Cookies from 'js-cookie';

export class MailPopup {

    constructor(element) {
        this.run();
    }

    popup() {
        const modal = document.querySelector('#modal-email');
        modal.classList.add('is-active');
        modal.querySelector('.modal-background').addEventListener('click', (e) => {
            this.closePopup();
        });
    }

    hasStartedCheckout() {
        return window.startedCheckout ?? false;
    }

    hasPoppedBefore() {
        if (window.location.hostname == 'localhost' || this.debug) {
            return false;
        }
        return (Cookies.get('email') == 'email');
    }

    trigger() {
        if (!this.hasPoppedBefore() && !this.hasStartedCheckout()) {
            this.popup();
        }
    }

    run() {

        setTimeout(() => {
            this.trigger();
        }, 3000);

        document.querySelector('button[data-accept="true"]').addEventListener('click', (e) => {
            this.send();
        });

        document.querySelector('a[data-accept="false"]').addEventListener('click', (e) => {
            Cookies.set('email', 'email', { expires: 15 });
            this.closePopup();
            e.preventDefault();
        });
    }

    closePopup() {
        document.querySelector('#modal-email').classList.remove('is-active');
    }

    getLanguage() {
        return (window.location.pathname.split('/')[1]);
    }

    getList() {
        const listFR = 'CJFTV1RmptKnZ2892zwqPNvg';
        const listEN = 'LvecO1Wmy892Aq763qtV0vcsFw';
        if (this.getLanguage() == 'fr') {
            return listFR;
        } else {
            return listEN;
        }
    }

    getMail() {
        return document.getElementById('mailfield').value;
    }

    getAccept() {
        return document.querySelector('input[name="accept"]').checked;
    }

    async send() {

        if (!this.getAccept()) {
            return false;
        }

        const mail = this.getMail();
        if (mail == '') {
            return false;
        }

        const parameters = {
            action: 'subscribe',
            email: mail,
            language: this.getLanguage()
        };

        const results = await fetch(window.location.href, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(parameters),
        });

        Cookies.set('email', 'email', { expires: 365 });
        this.closePopup();
    }

}
export class Modal {

    constructor(element) {
        this.element = element;
        this.type = element.dataset.type;
        if (this.type == 'trigger') {
            this.target = document.querySelector(element.dataset.target);
        }
        this.run();
    }

    run() {

        const that = this;

        if (this.type == 'trigger') {
            this.element.addEventListener('click', function (e) {
                e.preventDefault();
                that.target.classList.add('is-active');
            });
        } else if (this.type == 'modal') {
            const elems = this.element.querySelectorAll('[data-action="close"]');
            for (const elem of elems) {
                elem.addEventListener('click', function (e) {
                    e.preventDefault();
                    that.element.classList.remove('is-active');
                });
            }
        }

    }

}
export class NavbarHandler {

    constructor(element) {
        this.element = element;
        this.run();
    }

    run() {

        const that = this;

        const target = document.getElementById(this.element.dataset.target);

        this.element.addEventListener('click', function (e) {
            e.preventDefault();
            target.classList.toggle('is-active');
            that.element.classList.toggle('is-active');
        });


    }

}